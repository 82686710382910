<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Promotional items">
                
                <template v-if="$hasPermission($permissions.AddPromotionalItems)" >
                    <b-button class="mr-1" variant="primary" @click="addPromotionalItemModalActive = true">
                        <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                        />
                        <span class="align-middle">Add</span>
                    </b-button>
                    <b-button @click="$router.push({name: 'promotional_item-import-from-excel'})" class="mr-1">Import from Excel</b-button>
                </template>

                
                <basic-table :columns="columns" :data="promotionalItems" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="success" @click="$router.push('/promotional_item/' + props.row.id)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.status_raw === 0" variant="light-primary">
                            In warehouse
                        </b-badge>
                        <b-badge v-else-if="props.row.status_raw === 1" variant="light-success">
                            Assigned to outlet
                        </b-badge>
                        <b-badge v-else-if="props.row.status_raw === 2" variant="light-danger">
                            Decommissioned
                        </b-badge>
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <b-modal title="Add promotional item" v-model="addPromotionalItemModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Serial number</label>
                    <b-form-input v-model="addObject.serial_number"/>
                </b-form-group>

                <b-form-group>
                    <label>Type</label>
                    <v-select
                        v-model="addObject.promotional_item_type_id"
                        :reduce="type => type.id"
                        label="name"
                        :options="promotionalItemTypes"
                    />
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addPromotionalItemModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addPromotionalItem">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BBadge, BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,
            vSelect,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BBadge,
            BModal
        },
        data() {
            return {
                dataLoaded: false,
                promotionalItemsRaw: [],
                promotionalItemTypes: [],

                columns: [
                    {
                        label: 'Serial number',
                        displayType: 0,
                        field: 'serial_number',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Type',
                        displayType: 0,
                        field: 'promotional_item_type',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Status',
                        displayType: 2,
                        field: 'status',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Outlet',
                        displayType: 0,
                        field: 'outlet',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addPromotionalItemModalActive: false,
                addObject: {
                    serial_number: '',
                    promotional_item_type_id: 0
                },

                statusMap: new Map([[0, 'In warehouse'], [1, 'Assigned to outlet'], [2, 'Decommissioned']])
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/promotional_item')
                loadPromise.then(function(response) {
                    thisIns.promotionalItemsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const promotionalItemTypesPromise = this.$http.get('/api/management/v1/promotional_item_type')
                promotionalItemTypesPromise.then(function(response) {
                    thisIns.promotionalItemTypes = response.data
                    if (thisIns.promotionalItemTypes.length > 0) thisIns.addObject.promotional_item_type_id = thisIns.promotionalItemTypes[0].id
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, promotionalItemTypesPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addPromotionalItem() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/promotional_item', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Promotional item added')
                    thisIns.addPromotionalItemModalActive = false
                    thisIns.addObject.serial_number = ''
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            promotionalItems() {
                return this.promotionalItemsRaw.map((item, index) => {
                    return {
                        index,
                        id: item.id,
                        status_raw: item.status,
                        status: this.statusMap.get(item.status) || 'Unknown',
                        serial_number: item.serial_number,
                        promotional_item_type: (item.promotional_item_type.length > 0) ? item.promotional_item_type : 'Unknown',
                        outlet: (item.outlet.length > 0) ? item.outlet : 'Not defined'
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>